<template>
  <CRow>
    <CCol
      ><CToaster
        v-for="(toaster, toasterKey) in toasters"
        :position="toasterKey"
        :key="'toaster' + toasterKey"
      >
        <template v-for="(toast, key) in toaster">
          <CToast
            :key="'toast' + key"
            :color="toast.color"
            :show="true"
            v-bind="toast"
            class="text-white align-items-center"
          >
            {{ toast.msg }}
          </CToast>
        </template></CToaster
      ></CCol
    >
  </CRow>
</template>
<script>
export default {
  data() {
    return {
      toasts: [
        { position: "top-right", header: "BERHASIL TERHUBUNG", autohide: 2000 },
      ],
      autohide: true,
      autohideValue: 5000,
      closeButton: true,
      fade: true,
      position: "top-right",
      header: "Korfimasi",
    };
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    },
    headers() {
      if (this.$store.state.alert.variant == "success") {
        return "SUKSES";
      } else if (this.$store.state.alert.variant == "warning") {
        return "PERINGATAN";
      } else if (this.$store.state.alert.variant == "danger") {
        return "ERROR";
      } else {
        return "ERROR";
      }
    },
    toastConfig() {
      return {
        autohide: this.autohide ? this.autohideValue : false,
        closeButton: this.closeButton,
        fade: this.fade,
        position: this.position,
        header: this.headers,
        color: this.$store.state.alert.variant,
        msg: this.$store.state.alert.msg,
      };
    },
    toasters() {
      return this.toasts.reduce((toasters, toast) => {
        toasters[toast.position] = toasters[toast.position] || [];
        toasters[toast.position].push(toast);
        return toasters;
      }, {});
    },
  },
  methods: {
    addToast() {
      this.toasts.push(this.toastConfig);
    },
  },

  watch: {
    alert: function (old, news) {
      if (old != news) {
        this.addToast();
      }
    },
  },
};
</script>

<style scoped>
/* .toasterku {
  z-index: 9999;
} */
</style>