<template>
  <div>
    <Toast />
    <router-view></router-view>
  </div>
</template>

<script>
import { setToken } from "./store/utils/auth.js";
import Toast from "./component/Toast/toast.vue";

export default {
  name: "App",
  components: {
    Toast,
  },
  mounted() {
    setToken(localStorage.getItem("token"));
    this.$store.commit("set_lang", this.lang);
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";

.table-number {
  width: 60px;
}

.table-option {
  width: 110px;
}

.table-option-1 {
  width: 80px;
}

.table-option-2 {
  width: 100px;
}

.table-option-3 {
  width: 140px;
}

.table-option-4 {
  width: 160px;
}

.table-option-5 {
  width: 200px;
}

</style>
