import axios from "axios";
import Vue from "vue";
import Router from "vue-router";
import { ipBackend } from "@/ipBackend.js";
// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Login = () => import("@/views/Login");
const loginToken = () => import("@/views/loginToken");
const loker = () => import("@/views/loker");
const dokumenLoker = () => import("@/views/dokumenLoker");
const masterUser = () => import("@/views/hcms/master/user");
const KPI = ()=> import("@/views/KPI.vue")
const test = ()=> import("@/views/test.vue")

// HCMS
const DashboardHcms = () => import("@/views/DashboardHcms");
const masterDivisi = () => import("@/views/hcms/master/divisiNew");
const masterGaji = () => import("@/views/hcms/master/gajiPokok");
const masterPosisi = () => import("@/views/hcms/master/posisi");
const masterPelanggaran = () => import("@/views/hcms/master/pelanggaran");
const masterAsuransi = () => import("@/views/hcms/master/asuransi");
// const masterTunjanganTetap = () => import("@/views/hcms/master/tunjanganTetap");
const masterTunjanganTidakTetap = () =>
  import("@/views/hcms/master/tunjanganTidakTetap");
const masterTunjangan = () => import("@/views/hcms/master/tunjangan");
const masterPerusahaanPenempatan = () =>
  import("@/views/hcms/master/perusahaanPenempatan");
const masterDokAdministrasi = () =>
  import("@/views/hcms/master/dokAdministrasi");
const masterTahapanSeleksi = () => import("@/views/hcms/master/tahapanSeleksi");
const lowonganKerja = () => import("@/views/hcms/lowonganKerja/lowonganKerja");
const pelamarKerja = () => import("@/views/hcms/pelamarKerja/pelamar");
const pelamarKerjaDitolak = () =>
  import("@/views/hcms/pelamarKerja/pelamarKerjaDitolak");
const kelengkapanPelamar = () =>
  import("@/views/hcms/pelamarKerja/kelengkapanPelamar");

const tahapSeleksiPelamar = () =>
  import("@/views/hcms/tahapSeleksiPelamar/tahapSeleksiPelamar");

// const karyawan = () => import("@/views/hcms/karyawan/karyawan");
const karyawan = () => import("@/views/hcms/karyawan/newKaryawan");
const editKaryawan = () => import("@/views/hcms/karyawan/editKaryawan");
const kontrak = () => import("@/views/hcms/karyawan/suratPerintahKerja");
const perjanjianKerja = () =>
  import("@/views/hcms/karyawan/suratPerjanjianKerja");
const persetujuan = () => import("@/views/hcms/karyawan/suratPersetujuan");
const konfirmasi = () => import("@/views/hcms/suratKonfirmasi/suratKonfirmasi");
const AssesmentKaryawan = () => import("@/views/hcms/KPI/penilaian");
const potonganAsuransi = () =>
  import("@/views/hcms/potonganAsuransi/potonganAsuransi");

const masterKategoriPenilaian = () =>
  import("@/views/hcms/master/kategoriPenilaian");

const masterPenilaian = () => import("@/views/hcms/master/penilaian");
const masterPotongan = () => import("@/views/hcms/master/potongan");

const masterKeteranganIjin = () => import("@/views/hcms/master/keteranganIjin");

const masterCuti = () => import("@/views/hcms/master/cuti");

const masterShift = () => import("@/views/hcms/master/shift");
const masterJabatan = () => import("@/views/hcms/master/jabatan");

const masterKeteranganHapus = () =>
  import("@/views/hcms/master/keteranganHapus");

// const masterMitraPusat = () => import("@/views/hcms/master/mitraPusat");

const masterMitra = () => import("@/views/hcms/master/mitraBaru");
const masterMitraPembantu = () => import("@/views/hcms/master/mitraPembantu");
const kecelakaanKerja = () =>
  import("@/views/hcms/kecelakaanKerja/kecelakaanKerja");

const rekapAbsensi = () => import("@/views/Laporan/rekapAbsensi/rekapAbsensi");
const kelolaCuti = () => import("@/views/Laporan/rekapAbsensi/kelolaCuti");
const rekapGaji = () => import("@/views/Laporan/rekapGaji");
const laporanGaji = () => import("@/views/Laporan/laporanGaji");
const laporanTHR = () => import("@/views/Laporan/THR/THR");
const laporanLembur = () => import("@/views/Laporan/Lembur/Lembur");
const History = () => import("@/views/Laporan/Karyawan/karyawan");
const mutasi = () => import("@/views/hcms/Mutasi/mutasi");
const totalPotongan = () => import("@/views/Laporan/totalPotongan");
Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    // {
    //   path: "/hubungiKami",
    //   name: "Contact Us",
    //   component: contactUs,
    //   meta: {
    //     requiresAuth: false,
    //   },
    // },
    // {
    //   path: "/requestDemo",
    //   name: "Request Demo",
    //   component: requestDemo,
    //   meta: {
    //     requiresAuth: false,
    //   },
    // },

    // Backoffice
    {
      path: "/",
      name: "Login",
      component: Login,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/test",
      name: "test",
      component: test,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/penilaianKinerja",
      name: "KPI",
      component: KPI,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/loginToken",
      name: "Login Token",
      component: loginToken,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/loker",
      name: "Loker",
      component: loker,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/dokumenLoker/:postLokerId",
      name: "Dokumen Loker",
      component: dokumenLoker,
      meta: {
        requiresAuth: false,
      },
      beforeRouteUpdate(to, from, next) {
        console.log(to);
        if (to.path == "/dokumenLoker/:postLokerId") {
          next({
            path: "/DokumenLoker/",
          });
        }
      },
    },

    {
      path: "/admin",
      redirect: "/dashboardHcms",
      name: "Admin",
      component: TheContainer,
      children: [
        {
          path: "/dashboardHcms",
          name: "DashboardHcms",
          component: DashboardHcms,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/user",
          name: "Master User",
          component: masterUser,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/divisi",
          name: "Master Divisi",
          component: masterDivisi,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/gaji",
          name: "Master Gaji",
          component: masterGaji,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/posisi",
          name: "Master Posisi",
          component: masterPosisi,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/pelanggaran",
          name: "Master Pelanggaran",
          component: masterPelanggaran,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/dokAdministrasi",
          name: "Master Dokumen Administrasi Pelamar",
          component: masterDokAdministrasi,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/tahapanSeleksi",
          name: "Master Tahapan Seleksi Pelamar",
          component: masterTahapanSeleksi,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/kategoriPenilaian",
          name: "Master Kategori Penilaian",
          component: masterKategoriPenilaian,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/penilaian",
          name: "Master Penilaian",
          component: masterPenilaian,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/potongan",
          name: "Master Potongan",
          component: masterPotongan,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/keteranganIjin",
          name: "Master Keterangan Ijin",
          component: masterKeteranganIjin,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/keteranganHapus",
          name: "Master Keterangan Hapus / Dipecat",
          component: masterKeteranganHapus,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/cuti",
          name: "Master Cuti",
          component: masterCuti,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/asuransi",
          name: "Master Asuransi",
          component: masterAsuransi,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/tunjangan",
          name: "Master Tunjangan ",
          component: masterTunjangan,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        // {
        //   path: "/tunjanganTetap",
        //   name: "Master Tunjangan Tetap",
        //   component: masterTunjanganTetap,
        //   meta: {
        //     requiresAuth: true,
        //     role: "admin",
        //   },
        // },

        {
          path: "/tunjanganTidakTetap",
          name: "Master Tunjangan Tidak Tetap",
          component: masterTunjanganTidakTetap,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/masterShift",
          name: "Master Shift Karyawan",
          component: masterShift,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/masterJabatan",
          name: "Master Jabatan",
          component: masterJabatan,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/masterPerusahaanPenempatan",
          name: "Master Perusahaan Penempatan",
          component: masterPerusahaanPenempatan,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/masterMitra",
          name: "Master Mitra",
          component: masterMitra,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/masterMitraPembantu",
          name: "Master Mitra Pembantu",
          component: masterMitraPembantu,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/kecelakaanKerja",
          name: "Kecelakaan Kerja",
          component: kecelakaanKerja,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/lowonganKerja",
          name: "Lowongan Kerja",
          component: lowonganKerja,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/pelamarKerja",
          name: "Pelamar Kerja",
          component: pelamarKerja,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/pelamarKerjaDitolak",
          name: "Pelamar Kerja Ditolak",
          component: pelamarKerjaDitolak,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/kelengkapanPelamar",
          name: "Kelengkapan Pelamar",
          component: kelengkapanPelamar,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/tahapSeleksiPelamar",
          name: "Tahap Seleksi Pelamar",
          component: tahapSeleksiPelamar,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/karyawan",
          name: "Karyawan",
          component: karyawan,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/karyawan/edit/:id",
          name: "Edit Karyawan",
          component: editKaryawan,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/kontrak",
          name: "Kontrak",
          component: kontrak,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/perjanjianKerja",
          name: "Perjanjian",
          component: perjanjianKerja,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/suratPersetujuan",
          name: "Persetujuan",
          component: persetujuan,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/suratKonfirmasi",
          name: "Konfirmasi",
          component: konfirmasi,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/potonganAsuransi",
          name: "Potongan Asuransi",
          component: potonganAsuransi,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/mutasi",
          name: "Mutasi Karyawan",
          component: mutasi,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },

        {
          path: "/rekapAbsensi",
          name: "Rekap Absensi",
          component: rekapAbsensi,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/pengajuanCuti",
          name: "Pengajuan Cuti",
          component: kelolaCuti,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/laporanGaji",
          name: "Proyeksi Gaji",
          component: laporanGaji,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/rekapGaji",
          name: "Rekap Gaji",
          component: rekapGaji,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/History",
          name: "History Karyawan",
          component: History,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/totalPotongan",
          name: "Total Potongan",
          component: totalPotongan,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/THR",
          name: "Laporan THR",
          component: laporanTHR,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/lembur",
          name: "Laporan Lembur",
          component: laporanLembur,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
        {
          path: "/assesmentKaryawan",
          name: "Penilaian Karyawan By Mitra",
          component: AssesmentKaryawan,
          meta: {
            requiresAuth: true,
            role: "admin",
          },
        },
      ],
    },
  ],
});

router.beforeEach(async function (to, from, next) {
  if (!to.meta.requiresAuth) {
    next();
  } else {
    let x = await axios.get(ipBackend + "user/checkAuthentification", {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    if (x.data.message == "anda belum login" && to.path != "/") {
      console.log(x.data, "<<");
      next({
        path: "/",
      });
    } else if (x.data.message == "sukses" && to.path == "/") {
      console.log(x.data);
      next({});
    } else {
      next({});
    }
  }
});

export default router;
