import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  lang: "id",
  user_login: null,
  button: [],
  form: [],
  menu: [],
  table: [],
  modal: [],
  notif: true,
  alert: null,
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  },
  set_lang(state, lang) {
    state.lang = lang;
    state.table = table[lang];
    state.form = form[lang];
    state.modal = modal[lang];
  },
  set_notif(state, val) {
    state.notif = val;
  },
  set_alert(state, val) {
    // console.log(val, "alert");
    state.alert = val;
  },
};

const getters = {
  lang(state) {
    return state.lang;
  },
  button(state) {
    return state.button;
  },
  form(state) {
    return state.form;
  },
  menu(state) {
    return state.menu;
  },
  modal(state) {
    return state.modal;
  },
  table(state) {
    return state.table;
  },
};

const table = {
  id: {
    asuransi: "Master Asuransi",
    cuti: "Master Cuti",
    bagian: "Master Bagian",
    divisi: "Master Divisi",
    dokAdmin: "Master Dokumen Administrasi Pelamar",
    kategoriPenilaian: "Master Kategori Penilaian",
    shift: "Master Shift",
    posisi: "Master Posisi",
    penilaian: "Master Penilaian",
    pelanggaran: "Master Pelanggaran",
    pelamar: "Data Pelamar Kerja",
    user: "Master Pengguna",
    lowongan: "Master Lowongan Pekerjaan",
    karyawan: "Data Karyawan",
    perintah: "Data Surat Perintah",
    persetujuan: "Data Surat Persetujuan",
    perjanjian: "Data Surat Perjanjian Kerja",
    tahapan: "Master Tahapan Seleksi",
    tambahModal: "Tambah Data",
    perHalaman: "Per Halaman",
    cari: "Cari",
    cariHolder: "Ketik disini untuk Mencari ...",
    hapusCari: "hapus",
    tooltipEdit: "Edit Data",
    tooltipHapus: "Hapus Data",
    mutasi: "Mutasi",
    perpanjang: "Perpanjang Kontrak",
    jabatan: "Master Jabatan",
    konfirmasi: "Data Surat Konfirmasi",
    penilaian: "Data Penilaian Karyawan",
  },
  en: {
    asuransi: "Insurance",
    cuti: "Off Work",
    bagian: "Department",
    divisi: "Divisions",
    dokAdmin: "Applicant's Administration Docs",
    kategoriPenilaian: "Evaluation Categories",
    shift: "Shifts",
    posisi: "Positions",
    pelanggaran: "Violation",
    penilaian: "Evaluations",
    pelamar: "Applicant",
    lowongan: "Job Vacancies",
    user: "Users",
    karyawan: "Employee",
    perintah: "Work Order",
    persetujuan: "Employee Approval",
    perjanjian: "Employee Agreement",
    tahapan: "Selection's Phase",
    tambahModal: "Add",
    perHalaman: "Per Page",
    cari: "Search",
    cariHolder: "Type here to search ...",
    hapusCari: "Clear",
    tooltipEdit: "Edit",
    tooltipHapus: "Delete",
    mutasi: "Employee Mutation",
    perpanjang: "Renew Contract",
    jabatan: "Position Management",
    konfirmasi: "Confirmation Letter",
    penilaian: "Employee Assesment",
  },
};
const modal = {
  id: {
    perhatian: "Perhatian...!!!",
    tambahAsuransi: "Tambah Data Master Asuransi",
    editAsuransi: "Edit Data Master Asuransi",
    tambahCuti: "Tambah Data Cuti",
    editCuti: "Edit Data Cuti",
    tambahBagian: "Tambah Data Bagian",
    editBagian: "Edit Data Bagian",
    tambahDivisi: "Tambah Data Divisi",
    editDivisi: "Edit Data Divisi",
    tambahDokAdminPelamar: "Tambah Data Master Administrasi Pelamart",
    editDokAdminPelamar: "Edit Data Master Administrasi Pelamar",
    tambahKategoriEvaluasi: "Tambah Data Master Kategori Evaluasi",
    editKategoriEvaluasi: "Edit Data Master Kategori Evaluasi",
    tambahPecat: "Tambah Data Keterangan Hapus/ Dipecat",
    editPecat: "Edit Data Keterangan Hapus/ Dipecat",
    tambahIjin: "Tambah Data Master Keterangan Ijin",
    editIjin: " Edit Data Master Keterangan Ijin",
    tambahPenilaian: "Tambah Data Master Penilaian",
    editPenilaian: "Edit Data Master Penilaian",
    tambahPosisi: "Tambah Data Master Posisi",
    editPosisi: "Edit Data Master Posisi",
    tambahPenempatan: "Tambah Data Master Perusahaan Penempatan",
    editPenempatan: "Edit Data Master Perusahaan Penempatan",
    tambahShift: "Tambah Data Master Shift",
    editShift: "Edit Data Master Shift",
    tambahTahap: "Tambah Data Master Tahapan Seleksi Pelamar",
    editTahapan: "Edit Data Master Tahapan Seleksi Pelamar",
    tambahUser: "Tambah Data Master User",
    editUser: "Edit Data Master User",
    tambahPelamar: "Tambah Data Pelamar Kerja",
    editPelamar: "Edit Data Pelamar Kerja",
    detailPelamar: "Detail Pelamar",
    editKelengkapanPelamar: "Edit Data Kelengkapan Pelamar",
    tambahKaryawan: "Tambah Data Karyawan",
    editKaryawan: "Edit Data Karyawan",
    detailKaryawan: "Detail Karyawan",
    tambahKontrak: "Tambah Data Kontrak Karyawan",
    editKontrak: "Edit Data Kontrak Karyawan",
  },
  en: {
    perhatian: "Caution...!!!",
    tambahAsuransi: "Add Insurance",
    editAsuransi: "Edit Insurance",
    tambahCuti: "Add Off Work",
    editCuti: "Edit Off Work",
    tambahBagian: "Add Department",
    editBagian: "Edit Department",
    tambahDivisi: "Add Division",
    editDivisi: "Edit Division",
    tambahDokAdminPelamar: "Add Applicant Administration Document",
    editDokAdminPelamar: "Edit Applicant Administration Document",
    tambahKategoriEvaluasi: "Add Evaluation Category",
    editKategoriEvaluasi: "Edit Evaluation Category",
    tambahPecat: "Add Invalidation / Dismissal Notice",
    editPecat: "Edit Invalidation / Dismissa Notice",
    tambahIjin: "Add Absence Notice",
    editIjin: " Edit Absence Notice",
    tambahPenilaian: "Add Evaluation",
    editPenilaian: "Edit Evaluation",
    tambahPosisi: "Add Position",
    editPosisi: "Edit Position",
    tambahPenempatan: "Add Company Disposition",
    editPenempatan: "Edit Company Disposition",
    tambahShift: "Add Shift",
    editShift: "Edit Shift",
    tambahTahap: "Add Applicant Selection Stage",
    editTahapan: "Edit Applicant Selection Stage",
    tambahUser: "Add User",
    editUser: "Edit User",
    tambahPelamar: "Add Applicant Data",
    editPelamar: "Edit Applicant Data",
    detailPelamar: "Applicant Detail",
    editKelengkapanPelamar: "Edit Applicant Detail",
    tambahKaryawan: "Add Employee",
    editKaryawan: "Edit Employee",
    detailKaryawan: "Employee Detail",
    tambahKontrak: "Add Employee Contract",
    editKontrak: "Edit Employee Contract",
  },
};
const form = {
  id: {
    asuransi: "Asuransi",
    cuti: "Cuti",
    bagian: "Bagian",
    divisi: "Divisi",
    namaDokumen: "Nama Dokumen",
    kategoriPenilaian: "Kategori Penilaian",
    KeteranganPecat: "Keterangan Hapus / Dipecat",
    keteranganIjin: "Keterangan Ijin",
    penilaian: "Penilaian",
    posisi: "Posisi",
    kode: "Kode",
    perusahaan: "Perusahaan",
    alamat: "Alamat",
    telp: "No. Telepon",
    email: "Email",
    koorLintang: "Koordinat Lintang",
    koorBujur: "Koordinat Bujur",
    pic: "PIC",
    telpPIC: "No. Telepon PIC",
    emailPIC: "Email PIC",
    JabatanPIC: "Jabatan PIC",
    kategoriShift: "Kategori Shift",
    jamMasuk: "Jam Masuk",
    jamPulang: "Jam Pulang",
    tahapan: "Tahapan",
    namaPengguna: "Nama Pengguna",
    emailPengguna: "Email Pengguna",
    username: "Username",
    password: "Password",
    NIP: "NIP",
    divisi: "Divisi",
    judul: "Judul",
    keterangan: "Keterangan",
    tanggalAkhirLowongan: "Tanggal Akhir Lowongan",
    syaratDokAdministrasi: "Syarat Dok. Administrasi",
    posisiDilamar: "Posisi Yang Dilamar",
    fileCV: " File CV",
    tinggiBadan: "Tinggi Badan",
    beratBadan: "Berat Badan",
    agama: "Agama",
    kebangsaan: "Kebangsaan",
    jenisKelamin: "JenisKelamin",
    tempatLahir: "Tempat Lahir",
    tanggalLahir: "Tanggal Lahir",
    noTelp: "No. Telepon/HP",
    namaPasangan: "Nama Suami/Istri",
    noKTPPasangan: "No. KTP Suami/Istri",
    alamatPasangan: "Alamat Suami/Istri",
    jumlahAnak: "Jumlah Anak",
    anak1: "Anak 1",
    anak2: "Anak 2",
    fotoProfil: "Foto Profil",
    fotoKK: "Foto KK",
    asuransi: "Asuransi",
    potongan: "Potongan",
    nomorAsuransi: "nomor",
    dataKaryawan: "Data Karyawan",
    tanggalMulaiKontrak: "Tanggal Mulai Kontrak",
    durasiKontrak: "Durasi Kontrak(bulan)",
    detailKontrak: "Detail Kontrak",
    karyawan: "Karyawan",
    nomorPassport: "Nomor Passport",
    kitas: "Nomor KITAS",
    imta: "Nomor IMTA",
    masaBerlaku: "Masa Berlaku",
    statusAbsen: "Status Absen",
    simpan: "Simpan",
    batal: "Batal",
    tunggu: "Mohon Tunggu",
    ya: "Ya",
    tidak: "Tidak",
  },
  en: {
    asuransi: "Insurance",
    cuti: "Off Work",
    bagian: "Department",
    divisi: "Division",
    namaDokumen: "Documents Name",
    kategoriPenilaian: "Evaluation Category",
    KeteranganPecat: "Dismissal Description",
    keteranganIjin: "Absence Description",
    penilaian: "Evaluation",
    posisi: "Position",
    kode: "Code",
    perusahaan: "Company",
    alamat: "Address",
    telp: "Telephone",
    email: "Email",
    koorLintang: "Latitude",
    koorBujur: "Longitude",
    pic: "PIC",
    telpPIC: "PIC Phone",
    emailPIC: "PIC Email",
    JabatanPIC: "PIC Position",
    kategoriShift: "Shift Category",
    jamMasuk: "Start Time",
    jamPulang: "End Time",
    tahapan: "Stage",
    namaPengguna: "Name",
    emailPengguna: "User Email",
    username: "Username",
    password: "Password",
    NIP: "NIP",
    divisi: "Division",
    judul: "Title",
    keterangan: "Description",
    tanggalAkhirLowongan: "Tanggal Akhir Lowongan",
    syaratDokAdministrasi: "Syarat Dok. Administrasi",
    posisiDilamar: "Posisi Yang Dilamar",
    fileCV: " File CV",
    tinggiBadan: "Heigth",
    beratBadan: "Weight",
    agama: "Religion",
    kebangsaan: "Nationality",
    jenisKelamin: "Sex",
    tempatLahir: "Place of Birth",
    tanggalLahir: "Date of Birth",
    noTelp: "Phone Number",
    namaPasangan: "Spouse Name",
    noKTPPasangan: "Spouse ID",
    alamatPasangan: "Spouse Address",
    jumlahAnak: "Number Of Children",
    anak1: "1st Child",
    anak2: "2nd Child",
    fotoProfil: "Profile Picture",
    fotoKK: "Foto KK",
    asuransi: "Insurance",
    potongan: "Insurance Bill",
    nomorAsuransi: "Insurance Number",
    dataKaryawan: "Employee Data",
    tanggalMulaiKontrak: "Contract Start Date",
    durasiKontrak: "Contract Duration (month)",
    detailKontrak: "Contract Detail",
    karyawan: "Employee",
    nomorPassport: "Passport Number",
    kitas: "KITAS Number",
    imta: "IMTA Number",
    masaBerlaku: "Validity Period",
    statusAbsen: "Absent Status",
    simpan: "Save",
    batal: "Cancel",
    tunggu: "Please Wait",
    ya: "Yes",
    tidak: "No",
  },
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  plugins: [
    createPersistedState({
      key: "grinata",
    }),
  ],
});
